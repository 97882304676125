
// mutations.js
export default {
  SET_MOBILE(state, mobile) {
    state.mobile = mobile;
  },
  SHOW_SIDEBAR(state) {
    state.sidebar.visible = true;
  },
  HIDE_SIDEBAR(state) {
    state.sidebar.visible = false;
  },
  TOGGLE_SIDEBAR(state) {
    const current = state.layout.sidebar;
    state.sidebar.visible = !current;
  },
  SET_SIDEBAR_LOCKED(state, value) {
    state.sidebar.locked = value;
  },
  SET_LAYOUT_BREAKPOINT(state, value) {
    state.layout.breakpoint = value;
  },
  SET_SIDEBAR_COLLAPSABLE(state, value) {
    if (state.sidebar.locked) {
      return
    }
    state.sidebar.collapsible = value;
    state.sidebar.visible = !value;
  },

  SET_PROMOTION_DATA(state, value) {
    state.promotionApp = value;
  },
};
