// mutations.js
export default {
  SET_LIST_ALL_COURSES_DATA(state, payload) {
      state.listAllCourses = {
          ...state.listAllCourses,
          ...payload,
          courses: payload.courses,
      };
  },
};
