<template>
    <div class="promoting">
        <div class="promoting__container">
            <div class="promoting__container--icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                    <path
                        d="M8 16.25C8 15.8358 8.33579 15.5 8.75 15.5H11.25C11.6642 15.5 12 15.8358 12 16.25C12 16.6642 11.6642 17 11.25 17H8.75C8.33579 17 8 16.6642 8 16.25Z"
                        fill="#FE8700"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4 4C4 2.34315 5.34315 1 7 1H13C14.6569 1 16 2.34315 16 4V16C16 17.6569 14.6569 19 13 19H7C5.34315 19 4 17.6569 4 16V4ZM8 2.5V3.25C8 3.66421 8.33579 4 8.75 4H11.25C11.6642 4 12 3.66421 12 3.25V2.5H13C13.8284 2.5 14.5 3.17157 14.5 4V16C14.5 16.8284 13.8284 17.5 13 17.5H7C6.17157 17.5 5.5 16.8284 5.5 16V4C5.5 3.17157 6.17157 2.5 7 2.5H8Z"
                        fill="#FE8700"
                    />
                </svg>
            </div>
            <div class="promoting__container--content">
                <div class="promoting__container--text">
                    <div>
                        <span v-html="text.replace(/\n/g, '<br>')"></span>
                    </div>
                </div>

                <div class="promoting__container--links">
                    <a
                        :href="googlePlayLink"
                        target="_blank"
                        v-if="googlePlayLink?.length > 0"
                        rel="nofollow"
                    >
                        <TextPlay>Google Play</TextPlay>
                    </a>
                    <a
                        :href="appStoreLink"
                        target="_blank"
                        v-if="appStoreLink?.length > 0"
                        rel="nofollow"
                    >
                        <TextPlay>App Store</TextPlay>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.promoting {
    width: 100%;
    background-color: var(--background-color);

    @media screen and (max-width: 1139px) {
        padding: 0 1.25rem;
    }

    @media screen and (max-width: 600px) {
        padding: 0;
    }

    &__container {
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        @media screen and (max-width: 1139px) {
            align-items: flex-start;
            text-align: left;
            padding: 0;
            flex-grow: 0;
        }

        &--icon {
            flex-shrink: 0;

            svg > path {
                fill: var(--maincolor);
            }
        }

        &--content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 24px;

            @media screen and (max-width: 1139px) {
                flex-direction: column;
                flex: 1;
                gap: 10px;
            }
        }

        &--text {
            span {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                color: var(--fontcolor);
                display: block;
                white-space-collapse: break-spaces;
            }
        }

        &--links {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
            width: fit-content;

            @media screen and (max-width: 1139px) {
                align-items: flex-start;
                justify-content: flex-start;
                gap: 24px;
                width: 100%;
            }

            a {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                color: var(--maincolor);
                white-space: nowrap;
            }
        }
    }
}
</style>

<script>
import TextPlay from "../common/TextPlay.vue";

export default {
    name: "Promoting",

    components: {
        TextPlay,
    },

    props: {
        campaign: {
            type: String,
            required: true,
            default: "app",
            validator: (value) => ["app", "nps-research"].includes(value),
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
        appStoreLink: {
            type: String,
            required: false,
            default: "",
        },
        googlePlayLink: {
            type: String,
            required: false,
            default: "",
        },
    },
};
</script>
