var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"layout h-screen w-screen",attrs:{"id":"Layout"}},[(_vm.getModalBuyCourse.active)?_c('ModalSale',{attrs:{"data":_vm.modalContent},on:{"close":function($event){return _vm.actionShowModalBuyCourse({
                active: false,
                data: {},
            })}}}):_vm._e(),(_vm.getModalInfoCourse && _vm.getModalInfoCourse.active)?_c('ModalUnavailable',{on:{"close":function($event){return _vm.actionShowModalInfoCourse({
                active: false,
                data: {},
            })}}}):_vm._e(),_c('ModalController'),(_vm.getMe)?_c('modal-painel'):_vm._e(),(_vm.getMe)?_c('modal-novo-painel'):_vm._e(),_c('modal-categoria'),_c('my-component-certify'),_c('my-certificate-available'),_c('my-issuance-certificate'),(_vm.getMe)?_c('ModalCustomFields'):_vm._e(),_c('Sidebar'),_c('div',{staticClass:"layout-page"},[_c('div',{staticClass:"topbar",attrs:{"id":"topbar"}},[_c('div',{staticClass:"topbar__tools"},[_c('div',{staticClass:"topbar__tools__colapsable-menu"},[(_vm.sidebar.collapsible)?_c('a',{staticClass:"toggle-sidebar",on:{"click":_vm.actionToggleSidebar}},[_c('IconSax',{attrs:{"name":"menu-1"}})],1):_vm._e()]),(_vm.sidebar.collapsible)?_c('div',{staticClass:"topbar__tools__logo",class:{
                        'logo-mobile': _vm.layout.breakpoint == 1,
                        'logo-tablet':
                            _vm.layout.breakpoint === 2 ||
                            (_vm.layout.breakpoint > 2 &&
                                _vm.sidebar.visible === false),
                    }},[_c('router-link',{attrs:{"id":"home","to":"/home"}},[(
                                _vm.layout.breakpoint >= 2 &&
                                _vm.sidebar.visible === false
                            )?[(_vm.metasSite?.logo)?_c('img',{attrs:{"src":_vm.metasSite?.logo,"alt":"logo"}}):_vm._e()]:_vm._e(),(_vm.layout.breakpoint === 1)?[(_vm.metasSite?.logomarca)?_c('img',{attrs:{"src":_vm.metasSite?.logomarca,"alt":"logo"}}):_vm._e()]:_vm._e()],2)],1):_vm._e(),(_vm.layout.breakpoint >= 2)?_c('div',{staticClass:"topbar__tools__search"},[_c('Search')],1):_vm._e(),(_vm.layout.breakpoint >= 3)?_c('div',{staticClass:"topbar__tools__promoting"},[(_vm.promotionApp.active)?_c('Promoting',{attrs:{"campaign":"app","app-store-link":_vm.promotionApp.appStoreLink,"google-play-link":_vm.promotionApp.googlePlayLink,"text":_vm.$t('topbar.promoting.app.default_text')}}):(
                            !_vm.promotionApp.active &&
                            _vm.showLinkResearchNPS(false)
                        )?_c('NPSResearch',{attrs:{"link":_vm.getLinkResearchNPS}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"topbar__tools__actions",class:{ 'no-promotion': !_vm.promotionApp.active }},[_c('TopbarUser',{attrs:{"is-mobile":_vm.isMobile},on:{"toggle-search":function($event){_vm.searching = !_vm.searching}}})],1)])]),(_vm.layout.breakpoint < 3)?_c('div',{staticClass:"mobile-promotion-messaage"},[(_vm.promotionApp.active)?_c('Promoting',{attrs:{"campaign":"app","text":_vm.$t('topbar.promoting.app.default_text'),"appStoreLink":_vm.promotionApp.appStoreLink,"googlePlayLink":_vm.promotionApp.googlePlayLink}}):(
                    !_vm.promotionApp.active && _vm.showLinkResearchNPS(true)
                )?_c('NPSResearch',{attrs:{"link":_vm.getLinkResearchNPS,"is-mobile":_vm.isMobile}}):_vm._e()],1):_vm._e(),(_vm.searching)?_c('Search',{staticClass:"mobile-search",attrs:{"isMobile":true},on:{"clear":function($event){_vm.searching = false}}}):_vm._e(),_c('div',{staticClass:"layout-page__overflow"},[_c('div',{class:{
                    'site-content': true,
                    'site-content--sidebar': _vm.sidebar.visible,
                    'site-content--no-sidebar': _vm.sidebar.visible,
                    'site-content--mobile':
                        _vm.sidebar.visible && _vm.layout.breakpoint === 1,
                    [_vm.contentClass]: true,
                }},[_vm._t("default")],2),_c('div',{staticClass:"site-content--footer"},[_c('ScrollToTop'),(_vm.whatsapp)?_c('Whatsapp'):_vm._e()],1)])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.luzApagada)?_c('div',{staticClass:"modoFoco"}):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }