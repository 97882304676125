// getters.js
export default {
    getLayout: (state) => state.layout,
    getSidebar: (state) => state.sidebar,
    getSizing: (state) => state.sizing,
    getIsMobile: (state) => state.mobile < 768,
    getIsMedium: (state) => state.mobile >= 768 && state.mobile < 1024,

    getPromotionData: (state) => state.promotionApp,
    getPromotionPeriod: (state) => state.promotionApp.period,
    getPromotionActive: (state) => state.promotionApp.active,
    getPromotionStartDate: (state) => state.promotionApp.period?.start,
    getPromotionEndDate: (state) => state.promotionApp.period?.end,
    getAppStoreUrl: (state) => state.promotionApp.appStoreUrl,
    getGooglePlayUrl: (state) => state.promotionApp.googlePlayUrl,
};
