<template>
    <section id="Layout" class="layout h-screen w-screen">
        <!-- MODAL -->
        <ModalSale
            v-if="getModalBuyCourse.active"
            :data="modalContent"
            @close="
                actionShowModalBuyCourse({
                    active: false,
                    data: {},
                })
            "
        />
        <ModalUnavailable
            v-if="getModalInfoCourse && getModalInfoCourse.active"
            @close="
                actionShowModalInfoCourse({
                    active: false,
                    data: {},
                })
            "
        />
        <ModalController />

        <modal-painel v-if="getMe" />
        <!-- <modal-termos :siteid="currentSiteId"></modal-termos> -->
        <modal-novo-painel v-if="getMe"></modal-novo-painel>
        <modal-categoria></modal-categoria>
        <my-component-certify></my-component-certify>
        <my-certificate-available></my-certificate-available>
        <my-issuance-certificate></my-issuance-certificate>
        <ModalCustomFields v-if="getMe" />
        <!-- <ModalPlanos :percentage="valuePercentage" /> -->
        <Sidebar />

        <div class="layout-page">
            <div class="topbar" id="topbar">
                <div class="topbar__tools">
                    <div class="topbar__tools__colapsable-menu">
                        <a
                            @click="actionToggleSidebar"
                            class="toggle-sidebar"
                            v-if="sidebar.collapsible"
                        >
                            <IconSax name="menu-1" />
                        </a>
                    </div>

                    <div
                        class="topbar__tools__logo"
                        v-if="sidebar.collapsible"
                        :class="{
                            'logo-mobile': layout.breakpoint == 1,
                            'logo-tablet':
                                layout.breakpoint === 2 ||
                                (layout.breakpoint > 2 &&
                                    sidebar.visible === false),
                        }"
                    >
                        <router-link id="home" to="/home">
                            <template
                                v-if="
                                    layout.breakpoint >= 2 &&
                                    sidebar.visible === false
                                "
                            >
                                <img
                                    v-if="metasSite?.logo"
                                    :src="metasSite?.logo"
                                    alt="logo"
                                />
                            </template>
                            <template v-if="layout.breakpoint === 1">
                                <img
                                    v-if="metasSite?.logomarca"
                                    :src="metasSite?.logomarca"
                                    alt="logo"
                                />
                            </template>
                        </router-link>
                    </div>

                    <div
                        class="topbar__tools__search"
                        v-if="layout.breakpoint >= 2"
                    >
                        <Search />
                    </div>

                    <div
                        class="topbar__tools__promoting"
                        v-if="layout.breakpoint >= 3"
                    >
                        <Promoting
                            v-if="promotionApp.active"
                            campaign="app"
                            :app-store-link="promotionApp.appStoreLink"
                            :google-play-link="promotionApp.googlePlayLink"
                            :text="$t('topbar.promoting.app.default_text')"
                        />
                        <NPSResearch
                            v-else-if="
                                !promotionApp.active &&
                                showLinkResearchNPS(false)
                            "
                            :link="getLinkResearchNPS"
                        />
                    </div>

                    <div
                        class="topbar__tools__actions"
                        :class="{ 'no-promotion': !promotionApp.active }"
                    >
                        <TopbarUser
                            :is-mobile="isMobile"
                            @toggle-search="searching = !searching"
                        />
                    </div>
                </div>
            </div>
            <div class="mobile-promotion-messaage" v-if="layout.breakpoint < 3">
                <Promoting
                    v-if="promotionApp.active"
                    campaign="app"
                    :text="$t('topbar.promoting.app.default_text')"
                    :appStoreLink="promotionApp.appStoreLink"
                    :googlePlayLink="promotionApp.googlePlayLink"
                />
                <NPSResearch
                    v-else-if="
                        !promotionApp.active && showLinkResearchNPS(true)
                    "
                    :link="getLinkResearchNPS"
                    :is-mobile="isMobile"
                />
            </div>
            <Search
                class="mobile-search"
                v-if="searching"
                :isMobile="true"
                @clear="searching = false"
            />
            <div class="layout-page__overflow">
                <div
                    :class="{
                        'site-content': true,
                        'site-content--sidebar': sidebar.visible,
                        'site-content--no-sidebar': sidebar.visible,
                        'site-content--mobile':
                            sidebar.visible && layout.breakpoint === 1,
                        [contentClass]: true,
                    }"
                >
                    <slot></slot>
                </div>
                <div class="site-content--footer">
                    <ScrollToTop />
                    <Whatsapp v-if="whatsapp" />
                </div>
            </div>
        </div>

        <transition name="fade">
            <div class="modoFoco" v-if="luzApagada"></div>
        </transition>
    </section>
</template>

<script>
import ModalCategoria from "@/components/ModalCategoria.vue";
import ModalNovoPainel from "@/components/ModalNovoPainel.vue";
import ModalPainel from "@/components/ModalPainel.vue";
import ModalPlanos from "@/components/ModalPlanos.vue";
import ModalCustomFields from "@/components/modals/CamposPersonalizados.vue";
import NPSResearch from "@/components/NPSResearch.vue";
import Promoting from "@/components/Promoting/Component.vue";
import Search from "@/components/search/Search.vue";
import { mapActions, mapGetters } from "vuex";
import Sidebar from "./Sidebar.vue";
import TopbarUser from "./TopbarUser.vue";

import MemberMetaService from "@/services/resources/MemberMetaService";
import Cookies from "js-cookie";
import moment from "moment";
const serviceMemberMeta = MemberMetaService.build();

export default {
    name: "Layout",
    components: {
        ScrollToTop: () => import("@/components/common/ScrollToTop.vue"),
        Whatsapp: () => import("@/components/common/Whatsapp.vue"),
        Sidebar,
        Search,
        ModalController: () => import("./ModalController.vue"),
        TopbarUser,
        ModalPainel,
        ModalPlanos,
        ModalCustomFields,
        ModalNovoPainel,
        NPSResearch,
        ModalCategoria,
        ModalSale: () => import("@/components/common/modals/ModalSale.vue"),
        ModalUnavailable: () =>
            import("@/components/common/modals/ModalUnavailable.vue"),
        Promoting,
    },
    props: ["contentClass"],
    data() {
        return {
            modalContent: {
                cover: {},
                title: null,
                description: null,
                button: null,
                url: null,
            },
            luzApagada: false,
            client: {
                width: 0,
            },
            searching: false,
        };
    },
    computed: {
        ...mapGetters({
            metasSite: "user/metasSite",
            layout: "layout/getLayout",
            sidebar: "layout/getSidebar",
            getIsLoading: "config/getIsLoading",
            getMe: "auth/getMe",
            getModalInfoCourse: "home/getModalInfoCourse",
            getModalBuyCourse: "home/getModalBuyCourse",
            getSettings: "config/getSettings",
            getLinkResearchNPS: "config/getLinkResearchNPS",
            getPromotionData: "layout/getPromotionData",
        }),
        promotionApp() {
            let active = this.getPromotionData?.active || false;

            if (active) {
                let period = this.getPromotionData?.period || null;

                if (period) {
                    try {
                        const now = moment();
                        const startDate = moment(period.startDate);
                        const endDate = moment(period.endDate);

                        active =
                            now.isBetween(startDate, endDate, "day", "[]") &&
                            this.getMe.member.role == "student";
                    } catch (error) {
                        console.error(
                            "Invalid date format in promotion period"
                        );
                        active = false;
                    }
                }
            }

            return {
                active: active,
                appStoreLink: this.getPromotionData?.appStoreLink || "",
                googlePlayLink: this.getPromotionData?.googlePlayLink || "",
            };
        },
        isMobile() {
            return this.client.width <= 576;
        },
        whatsapp() {
            const whatsapp = this.getSettings["whatsapp"];
            const number = this.getSettings["whatsapp_number"];

            const messageCheck =
                whatsapp &&
                whatsapp === true &&
                typeof number === "string" &&
                number.length > 0;

            return messageCheck;
        },
    },
    methods: {
        ...mapActions({
            actionDefineMainColor: "config/actionDefineMainColor",
            actionAllowRequest: "config/actionAllowRequest",
            actionSetIsLoading: "config/actionSetIsLoading",
            actionFetchHomeData: "home/actionFetchHomeData",
            actionFetchMe: "auth/actionFetchMe",
            actionLoadTheme: "config/actionLoadTheme",
            actionRefreshLayout: "layout/actionRefreshLayout",
            actionToggleSidebar: "layout/actionToggleSidebar",
            getEssayPermission: "user/getEssayPermission",
            getHasPermission: "user/getHasPermission",
            getCertificateConfig: "user/getCertificateConfig",
            getMetasSite: "user/getMetasSite",
            actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
            actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
            actionShowModal: "app/actionShowModal",
            actionFetchPromotionStatus: "layout/actionFetchPromotionStatus",
        }),
        openModalMaintenance() {
            const hideScheduledMaintenance = Cookies.get(
                "hideScheduledMaintenance"
            );

            const deadline = new Date("2024-12-20T06:00:00");

            const now = new Date();

            if (!hideScheduledMaintenance && now < deadline) {
                this.actionShowModal({ id: "ScheduledMaintenance" });
            }
        },
        showLinkResearchNPS(isMobile) {
            return (
                this.getLinkResearchNPS &&
                this.isMobile == isMobile &&
                this.getMe.member.role == "student"
            );
        },
        openModalBuyCourse(item) {
            this.modalContent = item;
            this.actionShowModalBuyCourse({
                active: true,
                data: this.modalContent,
            });
        },
        async openModal(item) {
            this.modalContent = {
                date: item?.date,
                type: item?.type,
            };

            this.actionShowModalInfoCourse({
                active: true,
                data: this.modalContent,
            });
        },
        detectOs() {
            const userAgent =
                navigator.userAgent || navigator.vendor || window.opera;
            if (/android/i.test(userAgent)) {
                return "Android";
            }
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
            return "Desktop";
        },
        initWhatsApp() {
            const enabled = this.getSettings["whatsapp"];
            const number = this.getSettings["whatsapp_number"];
            const message = this.getSettings["whatsapp_message"];

            if (!enabled & !number & !message) {
                return;
            }
        },
        initJivoChat() {
            const enabled = this.getSettings["jivochat"];
            const id = this.getSettings["id_jivochat"];
            if (!enabled || !id) {
                return;
            }
            const jivoScript = document.createElement("script");
            jivoScript.setAttribute("src", `//code.jivosite.com/widget/${id}`);
            jivoScript.setAttribute("id", "jivoscript");
            jivoScript.async = true;
            document.head.appendChild(jivoScript);

            jivoScript.onload = () => {
                this.adjustElementsPosition();
            };
        },
        setLocale() {
            const localeMap = {
                US: "en",
                ES: "es",
            };
            const defaultLocale = "pt";

            serviceMemberMeta
                .search("keys[]=pais")
                .then((resp) => {
                    this.$i18n.locale = localeMap[resp.pais] || defaultLocale;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        adjustElementsPosition() {
            setTimeout(() => {
                const jvlabelWrap = document.getElementById("jvlabelWrap");
                const button = document.querySelector(".button_bcc8");

                if (jvlabelWrap) {
                    jvlabelWrap.style.right = "70px";
                }
                this.observeJivoChat();
            }, 2000);
        },
        observeJivoChat() {
            var target = document.getElementById("jcont");
            if (target) {
                var observer = new MutationObserver(function (mutations) {
                    mutations.forEach(function (mutation) {
                        if (mutation.type === "attributes") {
                            setTimeout(() => {
                                const jvlabelWrap =
                                    document.getElementById("jvlabelWrap");
                                if (jvlabelWrap) {
                                    jvlabelWrap.style.right = "70px";
                                }
                            }, 100);
                        }
                    });
                });

                var config = { attributes: true };
                observer.observe(target, config);
            } else {
                requestAnimationFrame(this.observeJivoChat);
            }
        },
        handleResize() {
            this.client.width = window.innerWidth;
        },
    },
    async mounted() {
        this.$root.$on("luzOn", () => {
            this.luzApagada = true;
        });
        this.$root.$on("luzOff", () => {
            this.luzApagada = false;
        });

        this.openModalMaintenance();
        await this.actionFetchPromotionStatus();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    async created() {
        await this.actionSetIsLoading(true);
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.setLocale();

        this.actionDefineMainColor();

        const promises = [
            this.actionRefreshLayout(),
            this.actionLoadTheme(),
            this.getEssayPermission(),
            this.getCertificateConfig(),
            this.getHasPermission(),
        ];

        try {
            await Promise.allSettled(promises);

            const routePath = this.$route.path;

            if (routePath === "/home") {
                await this.actionFetchHomeData();
            }

            if (this.detectOs() === "Android") {
                window.screen.orientation.lock("portrait");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            this.actionSetIsLoading(false);
        }
        this.initWhatsApp();
        this.initJivoChat();
    },
};
</script>

<style lang="scss" scoped>
#home {
    .logo-mobile {
        min-height: 40px;
        flex-basis: 116px;
        align-items: center;
        display: flex;

        img {
            min-width: 116px;
            max-width: 116px;
            display: block;
        }
    }

    .logo-tablet {
        min-height: 40px;
        flex-basis: 38px;
        align-items: center;
        display: flex;

        img {
            min-width: 38px;
            max-width: 38px;
            display: block;
        }
    }
}

.layout {
    @include flex-row;
    overflow: hidden;
}

.layout-page {
    @include flex-column;
    flex: 1;
    width: 0;
    height: 100vh;

    &__overflow {
        // display: flex;
        // width: 100%;

        overflow: auto;
    }
}

.site-content {
    flex: 1;
    min-height: calc(100vh - 184px);
    height: 100%;

    &--mobile {
        overflow: hidden;
    }
}

.mobile-promotion-messaage {
    display: none;
}

[data-device="smartphone"],
[data-device="tablet"] {
    .mobile-promotion-messaage {
        display: block;
        width: 100%;
        padding: 1rem;
    }
}

.topbar {
    position: relative;
    background-color: var(--topbar-background);
    border-bottom: 1px solid var(--topbar-border);
    padding: 0.625rem 2.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 4rem;

    &__tools {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;

        &__logo {
            &.logo-mobile {
                min-height: 40px;
                flex-basis: 116px;
                align-items: center;
                display: flex;

                img {
                    min-width: 116px;
                    max-width: 116px;
                    display: block;
                }
            }

            &.logo-tablet {
                min-height: 40px;
                flex-basis: 38px;
                align-items: center;
                display: flex;

                img {
                    min-width: 38px;
                    max-width: 38px;
                    display: block;
                }
            }
        }

        &__search {
            flex: 1;
            max-width: 500px;
        }

        &__promoting {
            width: fit-content;
        }

        &__actions {
            margin-left: auto;
        }
    }

    @media screen and (max-width: 600px) {
        justify-content: space-between;
        padding: 0.625rem 1rem;
        gap: 0;
    }
}

.toggle-sidebar {
    cursor: pointer;

    ::v-deep svg path {
        fill: var(--opposite-color);
    }
}

.mobile-search {
    padding: 1rem;
    flex-direction: row;
    width: 100%;
    height: 4.6rem;
}

.flex-1 {
    display: flex;
    align-items: center;
}
</style>
